.center {
    display: flex;
    justify-content: center;
}

.footer {
    bottom: 0;
    position: fixed;
    background: white;
    height: 30px;
}

.ionList {
    width: 90%;
    margin-top: 20px;
}

.ionIcon {
    padding-left: 5px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.ionCard {
    width: 90%;
}