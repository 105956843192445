.center {
    display: flex;
    justify-content: center;
}

.ionCard {
    width: 90%;
}

.footer {
    bottom: 0;
    position: fixed;
    background: white;
    height: 30px;
}
.justifyContent{
    display:flex;
    justify-content:space-between;
    width:'100%'
  }