.butonHeader{
    --color: #fff;
    width: 36px;
    font-size: 3px;
    padding-left: 1px;
}

.center {
    display: flex;
    justify-content: center;
}