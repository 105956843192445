.grid1 {
    height: 100%;
    padding: 0px;
    align-items: center;
}

.grupoKfc {
    width: 50%;
    height: 100px;
}

.sir {
    width: 275;
    height: 90px;
}

.contaierLogin {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
    width: 90%;
    height: 80vh;
    max-width: 515px;
}

.row1 {
    height: 30%;
    background: -moz-linear-gradient(45deg, rgba(180, 23, 73, 1) 0%, rgba(255, 0, 81, 0.9) 100%);
    background: -webkit-linear-gradient(45deg, rgba(180, 23, 73, 1) 0%, rgba(255, 0, 81, 0.9) 100%);
    background: linear-gradient(45deg, rgba(180, 23, 73, 1) 0%, rgba(255, 0, 81, 0.9) 100%);
}

.row2 {
    height: 70%;
}

.ionIcon{
    margin-right: 15px;
}

ion-slide {
    width: 90%;
    height: 90%;
    position: absolute !important;
    top: 0px !important;
}