ion-toolbar {
    --color: #ffffff;
    --background: rgba(180, 23, 73, 1);
}

ion-button {
    margin-top: 5px;
    margin-bottom: 15px;
    --background: rgba(180, 23, 73, 1);
}

ion-content {
    --background: url(../../../../../public/images/fondo.jpg);
}