.fabButton{
    position: fixed;
    
    ion-fab-button {
        --background: rgb(180, 23, 73);
        --background-activated: rgb(180, 23, 73);
        --background-hover: rgb(180, 23, 73);
        --border-radius: 15px;
        width: 40px;
        height: 40px;
    }
}