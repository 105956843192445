.items{
    color: black;
    font-weight: bold;
}

.col{
    border: 1px solid rgb(213 , 213 , 213);
}

.content{
    overflow: auto;
    height: 75vh;
    
    ion-row {
        border: 1px solid rgb(213 , 213 , 213);
        margin-bottom: 5px;
        border-radius: 4px;
    }

    h2 {
        color: black;
    }

    ion-input {
        padding: 10px 16px;
        font-size: 16px;
        color: var(--black);
        background-color: #fff;
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }

    ion-label {
        margin: 0;
    }
} 

.butonCancel {
    --background: #494949;
}

.iconSave {
    padding: 10px;
}

.btnMap {
    --box-shadow: none;
    margin-left: 5px;
}

.btnSearch {
    --box-shadow: none;
    width: 100%;
    font-size: 10px;
}

.detail {
    ion-row {
        border: 2px solid rgb(180, 23, 73) !important;
        color: black;
        width: 100%;
    }

    color: rgb(180, 23, 73) !important;   
}

.resultHeader {
    
    fieldset {
        border: 1px solid rgb(180, 23, 73);
        border-radius: 5px;
    }

    fieldset legend {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(180, 23, 73, 1);
        background: rgb(180, 23, 73);
    }
}

.contentRoadMap{
    overflow: auto;
    height: 65vh;
    
    ion-row {
        border: 1px solid rgb(213 , 213 , 213);
        margin-bottom: 5px;
        border-radius: 4px;
    }

    h2 {
        color: black;
    }

    ion-input {
        padding: 10px 16px;
        font-size: 16px;
        color: var(--black);
        background-color: #fff;
        border: 1px solid rgb(213 , 213 , 213);
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }

    ion-label {
        margin: 0;
    }
}

.rowItem {
    ion-row {
        width: 100%;
    }
}

.acorddionRow {
    border: none !important;
}