.content{
    height: 75vh;
    h2 {
        color: black;
        font-size: 14px;
    }

    ion-row{
        font-size: 12px;
    }

    ion-input {
        padding: 10px 16px;
        font-size: 12px;
        color: var(--black);
        background-color: #fff;
        border: 1px solid rgb(213 , 213 , 213);
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }
} 