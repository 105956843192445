.center {
    display: flex;
    justify-content: center;
}

.ionCard {
    width: 90%;
    height: 90%;

    ion-card-subtitle {
        display: flex;
        justify-content: right;
        padding: 10px;
        margin-right: 10px;
        color: black;
    }

    ion-searchbar {
        --border-radius: 10px;
        --background: white;
        --color: black;
        min-height: 5rem;
        margin-top: -1rem;
    }

    fieldset {
        border: 1px solid rgb(180, 23, 73);
        border-radius: 5px;
    }

    fieldset legend {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(180, 23, 73, 1);
        background: rgb(180, 23, 73);
    }
}

.Row {
    overflow: auto;
    height: 65vh;

    ion-card {
        box-shadow: 1px 1px 0px 2px rgb(180, 23, 73);
        color: black;
        height: 95px;

        ion-card-header{
            justify-content: center;
            display: flex;
            padding: 2px;
        }

        ion-card-content{
            text-align: center;
            font-size: 11px;
        }

        ion-icon{
            font-size: 35px;
        }
    }
}