.fieldPs{
   
  // margin-right: 25px;
   //margin-left: 25px;
    background: rgba(180, 23, 73, 1);
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 36px;
}
.fieldPsA{
    width: auto;
   margin-right: 5px;
   padding: 15px;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 36px;
}


.itemPs{
    width: 'auto';
    opacity: 80%;
    border-radius: 4px;
    height: 25px;
    min-height: 25px;
    --min-height: 25px;
}

.paginatorPs{
    
    height: 25px;
    margin: 0px;
}
.SelectPs{
    width: 50px;
    height: 25px;
    margin: 0px;
}