.resultHeader {
    height: 80vh;

    fieldset {
        border: 1px solid rgb(180, 23, 73);
        border-radius: 5px;
    }

    fieldset legend {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(180, 23, 73, 1);
        background: rgb(180, 23, 73);
    }

    ion-input {
        padding: 10px 16px;
        font-size: 16px;
        color: var(--black);
        background-color: #fff;
        border: 1px solid rgb(213 , 213 , 213);
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }
    
    h2 {
        color: black;
    }
}

.acordion {
    ion-item {
        --background: rgb(180, 23, 73); 
        color:white;
        border-radius: 8px;

        ion-icon{
            color: white;
        }
    }
}

.header {
    background: rgba(180, 23, 73, 1);
    color: white;
}

.iconDelete{
    color: rgba(180, 23, 73, 1);
    font-size: 25px;
}

.content{
    overflow: auto;
    height: 15vh;
}

.inputDetail{
    input{
        padding: 2px !important;
    }
}