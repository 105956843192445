.center {
    display: flex;
    justify-content: center;
}

.header {
    background: rgba(180, 23, 73, 1);
    color: white;
}

.items{
    color: black;
}

.content{
    overflow: auto;
    height: 85vh;
}