.center {
    display: flex;
    justify-content: center;

    ion-card {
        width: 90%;
        max-width: 500px;
    }

    ion-card-subtitle {
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-right: 10px;
        color: black;
        font-weight: bold;
    }

    ion-list{
        list-style: none;
        margin-left:10px;
        padding:0;
        position: relative;
        &:before{
            content:"";
            display: inline-block;
            width: 2px;
            background: grey;
            position: absolute;
            left:6px;
            top:20px;
            height: calc(100% - 55px );
        }

        ion-item{
            position: relative;
            padding-left: 15px;
            margin-bottom:15px;
            &:before{
                content:"";
                display: inline-block;
                width: 8px;
                height: 8px;
                background: white;
                position: absolute;
                left:0;
                top:20px;
                border-radius: 10px;
                border: 3px solid grey;
            }
        }
    }
}

.itemText {
    color: grey;
    text-align: justify;
    margin-bottom: 10px;
    
    ion-icon {
        font-size: 20px;
    }
}

.textFunction{
    display: flex;
    margin-left: 15px;
    font-size: 16px;
    color: grey;
}