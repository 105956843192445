.items{
    color: black;
    font-weight: bold;
}

.col{
    border: 1px solid rgb(213 , 213 , 213);
}

.content{
    overflow: auto;
    height: 65vh;
    
    ion-row {
        border: 1px solid rgb(213 , 213 , 213);
        margin-bottom: 5px;
        border-radius: 4px;
    }

    h2 {
        color: black;
    }

    ion-input {
        padding: 10px 16px;
        font-size: 16px;
        color: var(--black);
        background-color: #fff;
        border: 1px solid rgb(213 , 213 , 213);
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }
} 
